import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { matchPath } from "react-router";
import TagManager from 'react-gtm-module'

class Tagging extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}; 
  }

  componentDidMount() {
    // Load only

  	// dataLayer Analytics
    const TagManagerArgs = {
      gtmId: 'GTM-MCDPJ4T',
      dataLayer: {
        "brand": "BLG",
        "language": "en",
        "country": "gb",
        "siteTypeLevel": "main",
        "pageCategory": this.getPageCategory(this.props.location),
        "virtualPageURL": this.props.location,
        "virtualPageTitle": this.props.pageTitle
      }
    }

    // Initialize TagManager once
    TagManager.initialize(TagManagerArgs)

  }

  updateDataLayerPageview(){
  	window.dataLayer = window.dataLayer || [];
  	var json = {
  		'event':'updatevirtualpath',
  		"pageCategory": this.getPageCategory(this.props.location),
		'virtualPageURL': this.props.location,
  		"virtualPageTitle": this.props.pageTitle
  	}
  	window.dataLayer.push(json)

  }

  componentDidUpdate(prevProps, prevState) {
	  Object.entries(this.props).forEach(([key, val]) => {
		  	if(prevProps[key] !== val){
		  		if(key == "pageTitle") {
		  			this.updateDataLayerPageview()
		  		}
		  	}
		  }
	  );
	}

	getPageCategory(currentLocation) {

    let pageCategory = "other page";

    const isHome = matchPath(currentLocation, "/");

    const isHomePath = matchPath(currentLocation, "/home");

    if ((isHome && isHome.isExact) || (isHomePath && isHomePath.isExact))
      pageCategory = "homepage";
    const isProduct = matchPath(currentLocation, "/Products");
    if (isProduct && isProduct.isExact) pageCategory = "products page";

    const isBrandSingleCategory = matchPath(
      currentLocation,
      "/Products/:idCategory"
    );

    if (isBrandSingleCategory && isBrandSingleCategory.isExact)
      pageCategory = "products page";

    const isProductItem = matchPath(
      currentLocation,
      "/Products/:idCategory/:productItem"
    );

    if (isProductItem && isProductItem.isExact)
      pageCategory = "product detail page";

    const isSearch = matchPath(currentLocation, "/Search");
    if (isSearch && isSearch.isExact) pageCategory = "search results";

    const isAboutUs = matchPath(currentLocation, "/About-us");
    if (isAboutUs && isAboutUs.isExact) pageCategory = "aboutus page";

    const isLiveRAW = matchPath(currentLocation, "/LiveRAW");
    if (isLiveRAW && isLiveRAW.isExact) pageCategory = "liveraw page";


    return pageCategory
}


  render() {

    return (
      <div>
     	
      </div>
    );
  }
}

export default withSitecoreContext()(Tagging);
