import React from 'react';
import { DotLoader } from 'react-spinners';
import { css } from '@emotion/react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ReactComponent from "../../ReactComponent";

const override = css`
  margin: auto;
`;

class FilterCategories extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      itemdata: [],
      items: [],
      urlFilter: "",
      keyword: "",
      pageTag: "",
      categoryItems: "",
      categoryNames: "",
    }
  }

  componentDidMount() {
    sessionStorage.removeItem('filterCategories');
    sessionStorage.removeItem('filterCategoriesName');

    const url = window.location.href;
    if(this.props.sitecoreContext.route.fields.PfTag != null && this.props.sitecoreContext.route.fields.PfTag !== "undefined" && this.props.sitecoreContext.route.fields.FilterName != null){
      this.setState({
        pageTag: this.props.sitecoreContext.route.fields.FilterName.value
      })
    }
    if (this.props.fields.filterOn || url.includes('?tagId=')) {
      let criteria = this.props.fields.filterOn.id.replace(/-/g, "");

      if(window.location.search.includes('?search=')){
        let keywordText = window.location.search.substring(window.location.search.indexOf("=")+ 1);
        this.setState({
          keyword: keywordText
        });
      }

      let filterId = "";
      if (url.includes('?categoryId=') || url.includes('?tagId=')) {
        filterId = url.substring(url.indexOf("=") + 1);
        if (filterId.includes('#')) filterId = filterId.replace('#','');
        if (filterId.includes('-')) filterId = filterId.replace(/-/g,'').toUpperCase();
      }

      if (filterId === "") {
        sessionStorage.removeItem('filterCategories');
        sessionStorage.removeItem('filterCategoriesName');
      }

      let QUERY = 'query={ filters:search( index:"'+ this.indexName +'" fieldsEqual: [ {name: "_parent", value: "'+criteria+'"} ], rootItem: "/sitecore/content/' + this.appName + '/") { results { items { item { id name ...on BiolageProductTagTemplate { title { value } } } } } } criteria:search( index:"'+ this.indexName +'" fieldsEqual: [ {name: "_group", value: "'+criteria+'"} ], rootItem: "/sitecore/content/' + this.appName + '/") { results { items { item { id name template { name } ...on BiolagefolderProductTag { displayName title { value }   } } } } } }';
      fetch(this.getGraphqlEndpoint + QUERY)
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              itemdata: result,
              urlFilter: filterId,
            });

          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    }
  }

  handleSessionItems(itemId, itemName) {
    let categoryArr =  [];
    let categoryArrNames =  [];
    let sessionCategories = sessionStorage.getItem('filterCategories');
    let sessionCategoriesName = sessionStorage.getItem('filterCategoriesName');
    let getSessionItems =  sessionCategories !== null  ?  sessionCategories : "";
    let getSessionItemNames =  sessionCategoriesName !== null  ?  sessionCategoriesName : "";
    if(getSessionItems){
      let checkCategoryArr =  JSON.parse(getSessionItems);
      let checkCategoryNamesArr = JSON.parse(getSessionItemNames);

      let listOfCategory = JSON.parse(getSessionItems);
      let listOfCategoryNames = JSON.parse(getSessionItemNames);

      let indexName = checkCategoryNamesArr.indexOf(itemName);
      let index = checkCategoryArr.indexOf(itemId);

      //for Item Name of tags
      if (indexName !== -1) {
        let newArrayOfCategoryNames = checkCategoryNamesArr.filter(catName => catName !== itemName);
        sessionStorage.setItem("filterCategoriesName",JSON.stringify(newArrayOfCategoryNames));
      }
      else{
        let newCategoryArrNames = listOfCategoryNames;
        newCategoryArrNames.push(itemName);
        sessionStorage.setItem("filterCategoriesName",JSON.stringify(newCategoryArrNames));
      }

      //for Item Id of tags
      if (index !== -1) {
        let newArrayOfCategory = checkCategoryArr.filter(cat => cat !== itemId);
        sessionStorage.setItem("filterCategories",JSON.stringify(newArrayOfCategory));
      }
      else{
        let newCategoryArr = listOfCategory;
        newCategoryArr.push(itemId);
        sessionStorage.setItem("filterCategories",JSON.stringify(newCategoryArr));
      }

    }
    else{
      categoryArr.push(itemId);
      categoryArrNames.push(itemName);

      sessionStorage.setItem("filterCategories",JSON.stringify(categoryArr));
      sessionStorage.setItem("filterCategoriesName",JSON.stringify(categoryArrNames));
    }
  }

  handleClick(categoryClicked) {
    this.handleSessionItems(categoryClicked.item.id.toLowerCase(), categoryClicked.item.title.value);

    let getSessionUpdated = JSON.parse(sessionStorage.getItem('filterCategories'));
    let getSessionCategoryNamesUpdated = JSON.parse(sessionStorage.getItem('filterCategoriesName'));
    this.props.handleUpdateOnCategory(getSessionUpdated,getSessionCategoryNamesUpdated);
    let isPresent = false;
    this.props.handleShowProducts(true);

    for(var category of this.state.items) {
      if (category.item.title.value === categoryClicked.item.title.value) {
        isPresent = true;
      }
    }
    if (isPresent) {
      this.setState(state => {
        const items = state.items.filter(category => categoryClicked.item.title.value !== category.item.title.value);
        return {
          items,
          pageTag: ""
        }
      });
    } else {
      this.setState(state => {
        const items = [...state.items, categoryClicked];
        return {
          items,
          pageTag: categoryClicked.item.title.value
        }
      })
    }

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.urlFilter !== this.state.urlFilter && this.state.urlFilter !== "" && this.state.itemdata !== undefined) {
      for (let category of this.state.itemdata.data.filters.results.items) {
        if (this.state.urlFilter === category.item.id) {
          let items = [];
          this.setState(state => {
            items = [...state.items, category];
            return{
              urlFilter: "",
              items: items,
            }
          });

        }
      }
    }

    if (prevState.items !== this.state.items && this.state.itemdata.data !== undefined) {
      this.props.onFiltersChange({items:this.state.items, template:this.state.itemdata.data.criteria.results.items[0].item.template.name},this.state.keyword);
    }
  }

  render() {
    const { error, isLoaded, itemdata } = this.state;
    if (!this.props.fields.filterOn) return "";
    if (!isLoaded) return (
        <div className="widget commerce widget_product_categories">
          <div className='sweet-loading'>
            <DotLoader
                css={override}
                sizeUnit={"px"}
                size={50}
                color={'#9fce4e'}
                loading={!this.state.isLoaded}
            />
          </div>
        </div>
    );
    if (error) return `Error! ${error.message}`;
    if(!itemdata || !itemdata.data) return `Empty results`;

    let categories = [];
    for(var facet of this.props.facets) {

      if (itemdata.data.criteria.results.items[0].item.template.name === "biolagefolderProductTag" && facet.name === "tags") {
        categories = facet.values;
      }

    }

    let items = [];
    let facetIn = false;
    let selectedCat = false;
    for (let category of itemdata.data.filters.results.items) {
      facetIn = false;
      selectedCat = false;
      if (categories.some(categoryFaceted => category.item && categoryFaceted.item && category.item.title && categoryFaceted.item.title && category.item.title.value === categoryFaceted.item.title.value)) {
        facetIn = true;
      }
      if (facetIn && this.state.items.some(categorySelected => category.item && categorySelected.item && category.item.title && categorySelected.item.title && category.item.title.value === categorySelected.item.title.value)) {
        selectedCat = true;
      }
      if (facetIn && (category.item && category.item.title && this.state.pageTag === category.item.title.value)) {
        selectedCat = true;
      }
      if(category.item && category.item.title && this.state.urlFilter === category.item.id.toLowerCase()) {
        selectedCat = true;
        facet = true;
        this.handleSessionItems(category.item.id.toLowerCase(), category.item.title.value);
      }

      items.push(
          <li onClick={facetIn ? this.handleClick.bind(this, category) : null}>
            <a  className={
              !facetIn
                  ? ""
                  :
                  selectedCat
                      ? "selected"
                      : ""
            }>
              {category && category.item && category.item.title && category.item.title.value}
            </a>
          </li>
      );
    }


    return(
        <div className="widget commerce widget_product_categories">
          <h2 className="widget-title">{this.state.itemdata.data.criteria.results.items[0].item && this.state.itemdata.data.criteria.results.items[0].item.title.value}</h2>
          <ul className="product-categories">
            {items}
          </ul>
        </div>
    );
  }
}

export default withSitecoreContext()(FilterCategories);