import React from 'react';
import {Text, Image, RichText, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import StarRating from '../StarRating';
import { DotLoader } from 'react-spinners';
import { css } from '@emotion/react';
import NavLink from "react-router-dom/es/NavLink";
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/src/owl.carousel.css';
import logo from '../../assets/img/placeholder.png';
import productMas from "../../assets/img/product-mas.svg";
import ReactComponent from "../../ReactComponent";
import i18next from 'i18next';
import { loader as gqlLoader } from "graphql.macro";
import {Query} from "react-apollo";

const BestRatedProductsQuery = gqlLoader('./query.graphql');
const override = css`
  margin: auto;
`;

class BestRatedProducts extends ReactComponent {
  
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      productId: 0,
      myItem: 4
    };
    this.resizeItem = this.resizeItem.bind(this);
    this.carou = React.createRef();
  }

  componentDidMount(){
    this.resizeItem();
    window.addEventListener('resize', this.resizeItem);
  }

  resizeItem(){
    if(window.screen.width >= 320 && window.screen.width <= 425){
      this.setState({
        myItem: 1
      });
    }else if(window.screen.width >= 426 && window.screen.width <= 768){
      this.setState({
        myItem: 2
      });
    }else{
      this.setState({
        myItem: 4
      });
    }
  }

  render() {
    var ProductNumber = this.props.fields.ProductNumber.value;
    function SortByRating(x,y) {
      return ((x.item.rating.numberValue === y.item.rating.numberValue) ? 0 : ((x.item.rating.numberValue < y.item.rating.numberValue) ? 1 : -1 ));
    }
    const options = {
      items: this.state.myItem,
      loop: true,
      lazyLoad: true,
      nav: false,
      dots: false
    };
    return (
        <div className="row_custom_small related__product__custom bestRatedProd">
            <div className="col-md-12 pad ratedCustom">
                <Text field={this.props.fields.title} tag="h1"/>
            </div>
            <Query query={BestRatedProductsQuery} variables={{indexname: this.indexName, rootpath: '/sitecore/content/' + this.appName + '/'}}>
              {({ loading, error, data}) => {
                if (loading)
                  return(
                    <div className='sweet-loading' style={{marginTop:'50px', marginBottom: '50px'}}>
                      <DotLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#9fce4e'}
                        loading={!this.state.isLoaded}
                      />
                    </div>
                  );
                if (error)
                  return (
                      <div>Error: {error.message}</div>
                  );
                if (data.search == null){
                  return(
                    <p className="text-center w-100">
                      Nothing to display.
                    </p>
                  );
                }else{
                  return (
                      <>
                          <button className="btn chevrons_bestrated prev" onClick={() => this.carou.current.prev()}><i className="fas fa-chevron-left"/></button>
                          <OwlCarousel ref={this.carou} options={options} className={"col-md-10 bestrated"}>
                          {data.search.results.items &&
                            data.search.results.items.filter(
                              item => ((item.item !== null) && (item.item.id !== this.state.productId))).sort(SortByRating).slice(0, ProductNumber).map((product, index) => (
                              <React.Fragment key={index}>                                
                                <div key={`item-${index}`} className="portfolio-item text-center">
                                    <span className="plus__custom" data-toggle="modal"
                                          data-target={`#__${(product.item.id).substr(15)}`}>
                                      <img src={productMas} width="22" height="25" alt="" className="img_custom_full"/>
                                    </span>
                                  <a href={product.item && product.item.url}>
                                  <div className="portfolio-link">
                                    <div className="arround_grey"/>
                                    {product.item && product.item.image1.src === null ?
                                        <img src={logo} alt="placeholder" className="img-fluid img_custom_full placeholderCustom"/>
                                        :
                                        <img src={product.item && product.item.image1 && product.item.image1.value.src} className="img-fluid img_custom_full"/>
                                    }
                                    <div className="portfolio-caption min_font">
                                      <Text field={product.item && product.item.title} tag="h2"/>
                                      <StarRating value={product.item && product.item.rating.numberValue}/>
                                    </div>
                                  </div>
                                  </a>
                                  {product.item && product.item.url && product.item.url.startsWith("http") === true ?
                                      <a href={product.item && product.item.url} className="btn btn_custom_oro">{i18next.t("SeeMoreButton")}</a>
                                      :
                                      <NavLink to={product.item && product.item.url} className="btn btn_custom_oro">{i18next.t("SeeMoreButton")}</NavLink>
                                  }                                  
                                </div>                                
                              </React.Fragment>
                          ))}
                          </OwlCarousel>
                          <button className="btn chevrons_bestrated next" onClick={() => this.carou.current.next()}><i className="fas fa-chevron-right"/></button>
                          {data.search.results.items && data.search.results.items.filter(
                            item => ((item.item !== null) && (item.item.id !== this.state.productId))).sort(SortByRating).slice(0, ProductNumber).map((product, index) => (
                              <div className="modal fade customPopUpBestrated" key={index} id={`__${(product.item.id).substr(15)}`} tabIndex="-1" role="dialog" aria-labelledby="label_of_modal" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered modal-xl">
                                  <div className="modal-content">
                                    <button type="button" className="close text-right" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">×</span>
                                    </button>
                                    <div className="modal-body">
                                      <div className="col-md-5 text-center">
                                        {product.item && product.item.image1.src === null ?
                                            <img src={logo} alt="placeholder" className="img-fluid img_custom_full placeholderCustom" />
                                            :
                                            <img src={product.item && product.item.image1 && product.item.image1.value.src} alt={product.item && product.item.image1.alt} className="img_custom_full"/>
                                        }
                                      </div>
                                      <div className="col-md-7 text-left">
                                        <h2 className="popup-title_item helv_bd">{product.item && product.item.title.value}</h2>
                                        <p className="star-cn mb-0">
                                          <StarRating value={product.item && product.item.rating.numberValue}/>
                                        </p>
                                        <RichText field={product.item && product.item.longDescription} className="pop__description helv_lt"/>
                                        {this.state.productVariantURL &&
                                          (this.state.productVariantURL.startsWith("http") === true ?
                                            <a href={this.state.productVariantURL} className="btn btn_custom_oro helv_lt" target={"_blank"}>{i18next.t("BuyButton")}</a>
                                            :
                                            <NavLink to={this.state.productVariantURL} className="btn btn_custom_oro helv_lt" target={"_blank"}>{i18next.t("BuyButton")}</NavLink>
                                          )
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          ))}
                        </>
                    )}
                }}
            </Query>
        </div>
    );
  }


}

export default withSitecoreContext()(BestRatedProducts);
