import React from 'react';
import {Text, Image, RichText} from '@sitecore-jss/sitecore-jss-react';

class CategoriesNavigation extends React.Component {
  constructor(props) {
    super(props);
  }
  
  setSearchState(listItem) {
    let categoryList = [];
    let categoryNameList = [];
    categoryList.push(listItem.id.replace(/-/g, ""));
    categoryNameList.push(listItem.fields.title.value);
    sessionStorage.setItem("filterCategories",JSON.stringify(categoryList));
    sessionStorage.setItem("filterCategoriesName",JSON.stringify(categoryNameList));
    window.location.href = this.props.fields.tagLink && this.props.fields.tagLink.value.href + "?tagId=" + listItem.id.split('-').join('').toLowerCase()
  }

  render() {
    return (     
      <div className="row_custom_small section2">
        <div className="row_custom_small col-md-12 pad text-center">
          <h1 className={'helv_bd text-uppercase fontCustomNav'}><Text field={this.props.fields && this.props.fields.title} /></h1>
          <h2 className="mb-0 w-100 helv_md">
            <Text field={this.props.fields && this.props.fields.subtitle} />
          </h2>
        </div>
          <div className="row_custom_small nextSec2">
          {this.props && this.props.fields && this.props.fields.categoriesNavigationList &&
            this.props.fields.categoriesNavigationList.map((listItem, index) => (
            <div className="col-md-4 col-sm-6 col-xs-6 portfolio-item text-center" key={`sharedListItem-${index}`}>
              <a onClick={this.setSearchState.bind(this, listItem)} className="portfolio-link" >
                <img className="img-fluid img_custom_full" src={listItem.fields && listItem.fields.image&& listItem.fields.image.value.src} alt="" />
                <div className="portfolio-caption">
                  <h2 className="montserrat_bd">
                    <Text field={listItem.fields && listItem.fields.title} />
                  </h2>
                  <p className="montserrat">
                    <RichText field={listItem.fields && listItem.fields.description} />
                  </p>
                </div>
              </a>
              {listItem.fields && listItem.fields.ctadisplayname && listItem.fields.ctadisplayname.value !== "" &&
                <a onClick={this.setSearchState.bind(this, listItem)} className="btn btn_custom helv_rom btn_custom_green text-uppercase">
                  <Text field={listItem.fields && listItem.fields.ctadisplayname} />
                </a>
              }
            </div>
            ))}
          </div>
      </div>
    );
  }
}

export default CategoriesNavigation;