import React from 'react';
import { Text, Link, Image, withSitecoreContext, RichText } from '@sitecore-jss/sitecore-jss-react';
import { css } from '@emotion/react';
import { DotLoader } from 'react-spinners';
import {NavLink} from "react-router-dom";
import ReactComponent from "../../ReactComponent";
import {loader as gqlLoader} from "graphql.macro";
import {Query} from "react-apollo";

const ProductIngredientQuery = gqlLoader('./query.graphql');
const override = css`
  margin: auto;
`;

class ProductIngredients extends ReactComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        };
    }

    render() {
        return (
            <>
                {this.props.fields.Show && this.props.fields.Show.value === false ?
                    <div className="wrapper margin_custom">
                        <div className="row_custom_small custom_list noBackground">
                            <div className="col-md-12 text-center">
                                <h2>
                                    <Text field={this.props.fields.Title} />
                                </h2>
                            </div>
                            <div className="col-md-12 text-center list_ingredients">
                                <Query query={ProductIngredientQuery} variables={{
                                    productid: this.props.sitecoreContext.route.itemId.split('-').join(''),
                                    indexname: this.indexName,
                                    rootpath: "/sitecore/content/" + this.appName + "/"
                                }}>
                                    {({ loading, error}) => {
                                        if (loading)
                                            return (
                                                <div className='sweet-loading' style={{marginTop: '50px', marginBottom: '50px'}}>
                                                    <DotLoader
                                                        css={override}
                                                        sizeUnit={"px"}
                                                        size={50}
                                                        color={'#9fce4e'}
                                                        loading={!this.state.isLoaded}
                                                    />
                                                </div>
                                            );
                                        if (error)
                                            return (
                                                <div>Error: {error.message}</div>
                                            );

                                        let firstIngredient;
                                        if (this.props.sitecoreContext.route.fields.productIngredients)
                                            firstIngredient = this.props.sitecoreContext.route.fields.productIngredients;
                                        if (this.props.sitecoreContext.route.fields.ingredients)
                                            firstIngredient = this.props.sitecoreContext.route.fields.ingredients;

                                        if (firstIngredient)
                                            return (
                                                <>
                                                    {
                                                        firstIngredient.map((ing) => (
                                                            <div className="col-md-4 col-sm-12 col-xs-12">
                                                                <img src={ing && ing.fields.image && ing.fields.image.value.src} className="img-fluid img_custom_full" />
                                                                <strong className="name_ing_custom"><Text field={ing && ing.fields.title} editable="true"/></strong>
                                                                <p>
                                                                    <RichText field={ing && ing.fields.ShortDescription} editable="true" />
                                                                </p>
                                                            </div>
                                                        ))
                                                    }
                                                </>
                                            )
                                        else
                                            return(
                                                <div className={"col-md-4 btn_custom_green2"}>
                                                    <i>There is no ingredient defined</i>
                                                </div>
                                            )
                                    }}
                                </Query>
                            </div>
                            <div className="col-md-12 text-center btn_div_custom">
                                {this.props.fields.ViewAll.value.href && this.props.fields.ViewAll.value.href.startsWith("http") === true ?
                                    <Link href={this.props.fields.ViewAll.value.href} className="btn" />
                                    :
                                    <NavLink to={this.props.fields.ViewAll.value.href} className="btn">
                                        {this.props.fields.ViewAll.value.title}
                                    </NavLink>
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <div className="wrapper margin_custom">
                        <div className="row_custom_small custom_list">
                            <div className="col-md-12 pb-0">
                                <h2 className={"helv_md mb-0 text-center text-uppercase"}>
                                    <Text field={this.props.fields.Title}/>
                                </h2>
                            </div>
                            <div className="col-md-12 text-center list_ingredients pt-0">
                                <Query query={ProductIngredientQuery} variables={{
                                    productid: this.props.sitecoreContext.route.itemId.split('-').join(''),
                                    indexname: this.indexName,
                                    rootpath: "/sitecore/content/" + this.appName + "/"
                                }}>
                                    {({ loading, error}) => {
                                        if (loading)
                                            return (
                                                <div className='sweet-loading' style={{marginTop: '50px', marginBottom: '50px'}}>
                                                    <DotLoader
                                                        css={override}
                                                        sizeUnit={"px"}
                                                        size={50}
                                                        color={'#9fce4e'}
                                                        loading={!this.state.isLoaded}
                                                    />
                                                </div>
                                            );
                                        if (error)
                                            return (
                                                <div>Error: {error.message}</div>
                                            );

                                        let firstIngredient;
                                        if (this.props.sitecoreContext.route.fields.productIngredients)
                                            firstIngredient = this.props.sitecoreContext.route.fields.productIngredients[0];
                                        if (this.props.sitecoreContext.route.fields.ingredients)
                                            firstIngredient = this.props.sitecoreContext.route.fields.ingredients[0];

                                        if (firstIngredient)
                                            return (
                                                <>
                                                    <div className="col-md-6 col-sm-12 col-xs-12">
                                                        <img src={firstIngredient && firstIngredient.fields.image && firstIngredient.fields.image.value.src} className="img-fluid img_custom_full"  />
                                                    </div>
                                                    <div className="col-md-6 col-sm-12 col-xs-12">
                                                        <strong className="name_ing_custom text-uppercase helv_std mb-3">
                                                            <Text field={firstIngredient && firstIngredient.fields.title} editable="true"/>
                                                        </strong>
                                                        <p className="helv_std desc">
                                                            <RichText field={firstIngredient && firstIngredient.fields.ShortDescription} editable="true"/>
                                                        </p>
                                                        <div className="col-md-12 text-center btn_div_custom">
                                                            {this.props.fields.ViewAll && this.props.fields.ViewAll.value.href != null &&
                                                            (this.props.fields.ViewAll.value.href.startsWith("http") === true ?
                                                                <Link field={this.props.fields.ViewAll} className="btn" />
                                                                :
                                                                <NavLink to={this.props.fields.ViewAll.value.href} className="btn">
                                                                    {this.props.fields.ViewAll.value.text}
                                                                </NavLink>)
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        else
                                            return(
                                                <div className={"col-md-4 btn_custom_green2"}>
                                                    <i>There is no ingredient defined</i>
                                                </div>
                                            )
                                    }}
                                </Query>
                            </div>
                        </div>
                    </div>}
            </>
        );
    }
}
export default withSitecoreContext()(ProductIngredients);