import React from 'react';
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import {NavLink} from "react-router-dom";
import i18next from 'i18next';

const FeaturedPosts = (props) => {
    const {FeaturedPostsContentList} = props.fields;
    return (
        <>
            <div className="row_custom_small featured_posts_custom_heading">
                <div className="col-md-12">
                    <Text field={props.fields.Title} tag="h1"/>
                    <blockquote>
                        <Text field={props.fields.Text} tag="p"/>
                    </blockquote>
                </div>
            </div>
            <div className="row_custom_small box-1 featured_posts_custom">
                {FeaturedPostsContentList &&
                FeaturedPostsContentList.map((listItem, index) => (
                    <div className="col-md-4">
                        <img src={listItem.fields.Icon && listItem.fields.Icon.value.src}  class="img_custom_full"/>
                        <div className="desc-cn">
                            <h2>{`${index+1}#`}</h2>
                            <Text field={listItem.fields.title} tag="h3" className="helv_lt"/>
                            <p className="arrow">
                                <img className="alignnone size-full wp-image-1241 img_custom_full" src="https://www.biolage.es/wp-content/uploads/2018/11/flecha.png" alt=""  />
                            </p>
                            <RichText field={listItem.fields.content} tag="p" className="helv_lt"/>
                        </div>
                        {listItem.fields.PostLink && listItem.fields.PostLink.value.href.startsWith("http") === true ?
                            <a href={listItem.fields.PostLink} className="btn btn_oro helv_md" >{i18next.t("DiscoverMoreButton")}</a>
                            :
                            <NavLink to={listItem.fields.PostLink.value.href} className="btn btn_oro helv_md" >{i18next.t("DiscoverMoreButton")}</NavLink>
                        }
                    </div>

                ))}
            </div>
        </>
    );
};
export default FeaturedPosts;