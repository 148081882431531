import React from 'react';
import { Text,Image, withSitecoreContext  } from '@sitecore-jss/sitecore-jss-react';
import {NavLink} from "react-router-dom";

const TitleAndImage = (props) => {
    return(
    <section className="box-project">
        <div className="row_custom_small section3 text-center">
            <div className="col-md-12 pad">
                {props && props.fields && props.fields.Title && props.fields.Title.value !== "" && <Text field={props && props.fields && props.fields.Title} tag="h1" className="freeland"/>}
                {props && props.fields && props.fields.InternalLink && props.fields.InternalLink.value.href !== ""
                    ?
                    props && props.fields && props.fields.ImageBanner && props.fields.ImageBanner.value.src !== "" &&
                        <p className="home__img_marg">
                            {props && props.fields && props.fields.InternalLink.value.href && props.fields.InternalLink.value.href.startsWith("http") === true ?
                                <a href={props && props.fields && props.fields.InternalLink && props.fields.InternalLink.value && props.fields.InternalLink.value.href}>
                                    <img src={props && props.fields && props.fields.ImageBanner && props.fields.ImageBanner.value.src} className="img_custom_full"/>
                                </a>
                                :
                                <NavLink to={props && props.fields && props.fields.InternalLink && props.fields.InternalLink.value  && props.fields.InternalLink.value.href}>
                                    <img src={props && props.fields && props.fields.ImageBanner && props.fields.ImageBanner.value.src} className="img_custom_full"/>
                                </NavLink>
                            }
                        </p>
                    :
                    props && props.fields && props.fields.ImageBanner && props.fields.ImageBanner.value.src !== "" && <p className="home__img_marg no__hover"> <img src={props && props.fields && props.fields.ImageBanner && props.fields.ImageBanner.value.src} className="img_custom_full"/> </p>
                }
            </div>
        </div>
    </section>
  );
  
}


export default withSitecoreContext()(TitleAndImage);
