import React from 'react';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

class ArticleRichText extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    return(
        <div className="row_custom_small">
            <div className="col-md-12 text-left all-articles__custom newRT">
                <RichText field={this.props.sitecoreContext.route.fields.articleContent}/>
            </div>
        </div>
    )
  }
}

export default withSitecoreContext()(ArticleRichText);
