import React from 'react';
import { DotLoader } from 'react-spinners';
import { css } from '@emotion/react';
import "../../assets/css/paging.css";
import ReactComponent from "../../ReactComponent";
import {withSitecoreContext} from "@sitecore-jss/sitecore-jss-react";
const override = css`
  margin: auto;
`;

// if using customSearch, please rename to search (search:customSearch)

// PROPS : 
// - onItemsChange : Callback to update the items in the parent component. the setState method must be implemented in the parent and passed to this prop
// - query : provide a string replacing variables by ## followed by the query attribute name.
    // ex: first: ##first, after: "##after", conditions: [ ##fieldsEqual ] 
    // range: [{name:"saleprice_tf", from:"##rangeFrom", to: "##rangeTo"}]
    // ..., ##sortBy
// - itemsPerPage : number of items to retrieve for each page (corresponds to first query attribute). Default: 3
// - showIndexCount : boolean to show or hide indexCount part. ex: items 1 - 10 of 100
// - solrFields : Array of strings corresponding to the fieldsEqual of the query. ex: '{name: "categories_sm", value: "'+variable+'"},'
// if the solrfield is static in the query then there is no need to put it in the prop but rather directly in the query
// - range : range filter for customSearch, array of two
// - sort : array of 2 string : index name and either ASC or DESC
// - keyword : string
class Paging extends ReactComponent {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      itemdata: [],
      after: 0,
      initialize: false,
    };
    this.updateCursor = this.updateCursor.bind(this);
  }

  updateCursor(newPage, postPerPage = -1) {
    if (postPerPage === -1) {
      this.setState({
        after: newPage,
      })
    }
    else {
      let newCursor = newPage;
      newCursor = (newPage-1) * postPerPage;
      this.setState({
        after: newCursor
      })
    }
  }

  componentDidMount() {
    this.setState({initialize:true});
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.itemdata !== prevState.itemdata) {
      this.props.onItemsChange(this.state.itemdata);
    }
    if(this.state.initialize !== prevState.initialize || this.state.after !== prevState.after || 
      this.props.solrFields !== prevProps.solrFields || this.props.itemsPerPage !== prevProps.itemsPerPage ||
      this.props.sort !== prevProps.sort || this.props.keyword !== prevProps.keyword) {
      
      let postsPerPage = this.props.itemsPerPage !== undefined ? this.props.itemsPerPage : 3;
      if (postsPerPage === undefined || postsPerPage === null || isNaN(postsPerPage) || postsPerPage === 0 ||  postsPerPage < 0) postsPerPage = 3;
      var startCursor = "";
      startCursor = this.state.after.toString();
      if (this.state.after === prevState.after) {
        this.setState({
          after: 0,
          itemdata: [],
          isLoaded: false,
        });
        startCursor = 0;
      }
      else {
        this.setState({
          isLoaded:false,
          itemdata:[]
        });
      }

      let solrFields = this.props.solrFields !== undefined ? this.props.solrFields : [];
      let sort = this.props.sort !== undefined ? this.props.sort : [];
      let keyword = this.props.keyword !== undefined ? this.props.keyword : "";
      var QUERY = this.props.query;
      while (QUERY.includes("##first")) QUERY = QUERY.replace("##first", postsPerPage);
      while (QUERY.includes("##after")) QUERY = QUERY.replace("##after", startCursor);
      while (QUERY.includes("##fieldsEqual")) {
        if (solrFields.length !== 0) QUERY = QUERY.replace("##fieldsEqual", solrFields.join(''));
        else QUERY = QUERY.replace("##fieldsEqual", solrFields);
      }
      while (QUERY.includes('##keyword')) QUERY = QUERY.replace("##keyword", keyword.replace(/%20/g, " "));
      while (QUERY.includes("##sortBy")) {
        if (sort.length !== 0) QUERY = QUERY.replace("##sortBy", 'sortBy: {name:"'+sort[0]+'", order:"'+sort[1]+'"},');
        else QUERY = QUERY.replace("##sortBy", '');
      }

      var postQuery = QUERY.substring(QUERY.indexOf("=") + 1);

      var req = {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: postQuery
        })
      };
      fetch(this.getGraphqlEndpoint, req)
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              itemdata: result,
            });
            
          },
  
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    }
  }

  render() {

    const { error, isLoaded, itemdata } = this.state;
    let postsPerPage = this.props.itemsPerPage !== undefined ? this.props.itemsPerPage : 3;
    if (postsPerPage === undefined || postsPerPage === null || isNaN(postsPerPage) || postsPerPage === 0  || postsPerPage < 0) postsPerPage = 3;

    if (!isLoaded) return (
      <div className='sweet-loading'>
        <DotLoader
          css={override}
          sizeUnit={"px"}
          size={50}
          color={'#9fce4e'}
          loading={!this.state.isLoaded}
        />
      </div>
    );
    if (error) return `Error! ${error.message}`;
    if(!itemdata || !itemdata.data) return `Empty results`;

    const hasNextPage = itemdata.data.search.results.pageInfo.hasNextPage;
    const totalCount = itemdata.data.search.results.totalCount;
    const hasPreviousPage = itemdata.data.search.results.pageInfo.hasPreviousPage;
    const startCursor = itemdata.data.search.results.pageInfo.startCursor;

    const nbPages = Math.floor(totalCount / postsPerPage) + (totalCount % postsPerPage === 0 ? 0 : 1);
    const currentPage = startCursor === 1 ? 1 : Math.floor(startCursor / postsPerPage) + (startCursor % postsPerPage === 0 ? 0 : 1);
    let pages = [];
    if (nbPages <= 4)
    {
      for(let i=1;i<=nbPages;i++) {
        if (currentPage === i) {
          pages.push(<span className="page-numbers current">{i}</span>);
        }
        else {
          pages.push(<a className="page larger if" title={`Page ${i}`}  onClick={() => this.updateCursor(i, postsPerPage)}>{i}</a>);
        }
      }
    }    
    else {
      if(currentPage>1) {
        pages.push(<a className="page larger" title={`Page ${currentPage-1}`}  onClick={() => this.updateCursor(currentPage-1, postsPerPage)}>{currentPage-1}</a>);
      }
      for(let i=currentPage;i<=currentPage+1;i++) {
        if (currentPage === i) {
          pages.push(<span aria-current="page" className="current">{i}</span>);
        }
        else {
          if(i<=nbPages) {
            pages.push(<a className="page larger" title={`Page ${i}`}  onClick={() => this.updateCursor(i, postsPerPage)}>{i}</a>);
          }                    
        }
      }
      if(currentPage === 1) {
        pages.push(<a className="page larger" title={`Page ${currentPage+2}`}  onClick={() => this.updateCursor(currentPage+2, postsPerPage)}>{currentPage+2}</a>);
      }
    }

    return(
      <>
        <div className="row_custom_small">
            <div className="col-md-12 text-center">
              <div className="pagenavi" role="navigation">
                {pages &&
                  <>
                    {hasPreviousPage &&
                      <a className="previouspostslink" rel="previous"  onClick={() => this.updateCursor((this.state.after) - postsPerPage)}>&lt;</a>
                    }
                    {pages}
                    {hasNextPage &&
                      <a className="nextpostslink" rel="next"  onClick={() => this.updateCursor(parseInt(this.state.after) + parseInt(postsPerPage))}>&gt;</a>
                    }
                  </>
                }
              </div>
          </div>
        </div>
      </>
    );
  }
}

export default withSitecoreContext()(Paging);
