import React from 'react';
import ReactComponent from "../../ReactComponent";
import {withSitecoreContext} from "@sitecore-jss/sitecore-jss-react";
import {loader as gqlLoader} from "graphql.macro";
import {DotLoader} from "react-spinners";
import {Query} from "react-apollo";
import {css} from "@emotion/react";

const parentRouteQuery = gqlLoader('./query.graphql');
const override = css`
  margin: auto;
`;

class ParentRoute extends ReactComponent{

    constructor(props) {
        super(props);
        
        this.state = {
          error: null,
          isLoaded: false,
          ListParents: ''
        };
      }

    render(){
        return(
            <Query query={parentRouteQuery} variables={{currrentcontextitem: this.props.CurrentId}}>
                {({ loading, error, data}) => {
                    if (loading)
                        return (
                            <div className='sweet-loading' style={{marginTop: '50px', marginBottom: '50px'}}>
                                <DotLoader
                                    css={override}
                                    sizeUnit={"px"}
                                    size={50}
                                    color={'#9fce4e'}
                                    loading={!this.state.isLoaded}
                                />
                            </div>
                        );
                    if (error)
                        return (
                            <div>Error: {error.message}</div>
                        );
                    return (
                        <React.Fragment key={`parentRoute`}>
                            <nav className="breadcrumb__custom">
                                {
                                    data.breadcrumbs && JSON.parse(data.breadcrumbs).ListParents.map((item, idx) => (
                                            <React.Fragment key={idx}>
                                                <a href={item.Link}>{item.Text}</a>&nbsp;/&nbsp;
                                            </React.Fragment>
                                        )
                                    )
                                }
                                {this.props.CurrentItemName}
                            </nav>
                        </React.Fragment>
                    );
                }}
            </Query>
        )
    }

}
export default withSitecoreContext()(ParentRoute);