import React from 'react';
import { Image, Link, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import i18next from 'i18next';
import {NavLink} from "react-router-dom";
import ReactComponent from "../../ReactComponent";

class StoreList extends ReactComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        };
    }

    render() {
        return (
            <div className="dropdown">
                <a className="btn btn-secondary dropdown-toggle text-uppercase helv_std" role="button" id="dropdownfirst"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {i18next.t("ShopOnlineLabel")}
                </a>
                <div className="dropdown-menu" aria-labelledby="#dropdownfirst">
                    {this.props.fields && this.props.fields.StoreList && this.props.fields.StoreList.map((storeItem, storeIndex) => (
                        storeItem.fields && storeItem.fields.StoreLink && storeItem.fields.StoreLink.value.href.startsWith("http") === true ?
                            <Link className="dropdown-item" field={storeItem.fields && storeItem.fields.StoreLink} key={storeIndex} ><img src={storeItem.fields && storeItem.fields.Brand && storeItem.fields.Brand.fields && storeItem.fields.Brand.fields.StoreImage && storeItem.fields.Brand.fields.StoreImage.value.src} /></Link>
                            :
                            <NavLink to={storeItem.fields.StoreLink.value.href} className="dropdown-item" key={storeIndex}><img src={storeItem.fields && storeItem.fields.Brand && storeItem.fields.Brand.fields && storeItem.fields.Brand.fields.StoreImage && storeItem.fields.Brand.fields.StoreImage.value.src} /></NavLink>
                    ))}
                </div>
            </div>
        )
    }
}

export default withSitecoreContext()(StoreList);