import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import i18next from 'i18next';

class FilterSearch extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      init: true,
      keyword:"",
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.init && nextProps.facets.length !== 0) {
      const url = window.location.href;
      let keyword = "";
      if (url.includes('?search=')) {
        keyword = url.substring(url.indexOf("=") + 1);
        if (keyword.includes('#')) keyword = keyword.replace('#','');
        this.props.onKeywordChange(keyword);
        this.setState({
          keyword:keyword,
          init: false,
        })
      }
    }
    if (nextProps.resetSearch !== this.props.resetSearch) {
      this.setState({
        keyword: ""
      })
    }
  }

  handleClick() {
    this.props.onKeywordChange(this.state.keyword);
  }

  handleChange(e) {
    this.setState({
      keyword:e.target.value,
    })
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.onKeywordChange(this.state.keyword);
  }

  render() {

    return(
      <div className="widget widget_search">
        <Text field={this.props.fields.title} tag="h2" className="widget-title" />
        <form id="searchform" onSubmit={this.handleSubmit.bind(this)}>
          <label className="sr-only">{i18next.t("SearchLabel")}</label>
          <input type="search" className="form-control" value={this.state.keyword} placeholder="Search something…" onChange={this.handleChange.bind(this)} />
          <input type="submit" id="sidebarSearchSubmit" className="hidden" name="submit" value="Search" />
          <div className={"filterSe"} onClick={this.handleClick} />
        </form>
      </div>
    );
  }
}

export default FilterSearch;
