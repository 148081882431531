import React from 'react';
import { Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { css } from '@emotion/react';
import { DotLoader } from 'react-spinners';
import {NavLink} from "react-router-dom";
import i18next from 'i18next';
import ReactComponent from "../../ReactComponent";
import {loader as gqlLoader} from "graphql.macro";
import {Query} from "react-apollo";

const SocialMediaArticleQuery = gqlLoader('./query.graphql');
const override = css`
  margin: auto;
`;

class SocialMediaArticles extends ReactComponent {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false
    };
  }

  render() {
      return(
        <Query query={SocialMediaArticleQuery} variables={{
          indexname: this.indexName, rootpath:  this.props.sitecoreContext.route.itemId.split('-').join('').toLowerCase()
        }}>
          {({loading, error, data}) => {
            if (loading)
              return (
                  <div className='sweet-loading' style={{marginTop: '50px', marginBottom: '50px'}}>
                    <DotLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#9fce4e'}
                        loading={!this.state.isLoaded}
                    />
                  </div>
              );
            if (error)
              return (
                  <div>Error: {error.message}</div>
              );
            return (
                <div className="row_custom_small">
                  <div className="link_social">
                    {
                      data.product.results.items.map(function (sociallink, idx) {
                        return (
                          <>
                            {sociallink && sociallink.item && sociallink.item.internalLink && sociallink.item.internalLink.url &&
                              (sociallink.item.internalLink.url.startsWith("http") ?
                                  <a href={sociallink && sociallink.item && sociallink.item.internalLink} className="text-dark" key={idx}>
                                    <img src={sociallink.item.image && sociallink.item.image.value.src} className="img_custom_full"/>
                                  </a>
                                  :
                                  <NavLink to={sociallink && sociallink.item && sociallink.item.internalLink && sociallink.item.internalLink.url} className="text-dark" key={idx}>
                                    <img src={sociallink && sociallink.item && sociallink.item.image && sociallink.item.image.value.src} className="img_custom_full"/>
                                  </NavLink>
                              )
                            }
                          </>
                        )
                      })
                    }
                    <span className="border-0 freeland">
                    {i18next.t("LiveRawLabel")}
                    </span>
                  </div>
                </div>
            )
          }}
        </Query>
      )
  }
}

export default withSitecoreContext()(SocialMediaArticles);
